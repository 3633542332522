@import "~base/experience/components/commerceAssets/campaignBanner";
@import "../../../utilities/unit";
@import "../../../variables";

.campaign-banner {
    height: auto;
    background-color: $primary;

    .campaign-banner-container {
        height: auto;
    }

    .campaign-banner-message {
        margin: rem-calc(10 0);
        padding: rem-calc(0);
        line-height: 1;
        font-family: $font-antenna-purina;
        font-size: rem-calc(14);
        font-weight: normal;
        color: $black;

        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            font-size: rem-calc(14);
            line-height: 1;
        }

        a:visited {
            color: $thin-banner-visited-link-color;
        }

        a {
            color: $thin-banner-link-color;
            margin-left: 1em;
        }

        p {
            margin-bottom: 0;
        }
    }

    .close-button {
        margin-right: 1em;

        .close {
            opacity: 1;
            width: 100%;
            height: auto;
            padding: rem-calc(8 0);

            img {
                width: 0.7em;
            }
        }
    }
}
